import http, {Service} from './common'
const resource = 'issue'
class IssueService extends Service {
  save(issue, files) {
    if (issue) {
      let formData = new FormData();
      for(let file of files) {
          formData.append("images", file, file.name);
      }
      for (let key in issue) {
        formData.append(key, issue[key]);
      }
      const headers = { 'Content-Type': 'multipart/form-data' };
      if (issue.id) {
        return http.put(`/${this.resource}/${issue.id}`, formData, {headers}).then(response => response.data);
      } else {
        return http.post(`/${this.resource}/`, formData, { headers }).then(response => response.data || {});
      }
    } else {
      console.error("Content is empty");
    }
  }
  loadImages(issue) {
    let uri = `/image/?ref=Issue&code=${issue.issueCode}`
    return http.get(uri).then((response) => {
      if (Array.isArray(response.data)) {
        return response.data.map(content => {
          if (typeof content == 'string') {
              return `data:image/png;base64, ${content}`
          } else if (typeof content == 'object') {
              let res = {};
              if (content.image) {
                res.imageSrc = `data:image/png;base64, ${content.image}`
              }
              if (content.thumbnail) {
                res.thumbnailSrc = `data:image/png;base64, ${content.thumbnail}`
              }
              return res;
          }
        });
      } else if (response.data){
        `data:image/png;base64, ${response.data}`
      }
    });
  }
  getPackages() {
    return http.get(`/${resource}/packages`).then(res => res.data)
  }
  getReceiptCodes() {
    return http.get(`/${resource}/receiptCodes`).then(res => res.data)
  }
  saveMessage(issueId, message) {
    let data = {
      issueId: issueId,
      message: message
    };
    return http.post(`/${resource}/message`, data).then(res => res.data)
  }
  deleteMessage(id) {
    return http.delete(`/${resource}/message/${id}`).then(res => res.data)
  }
}

export default new IssueService(resource)
